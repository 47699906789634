import s from "./NewsPages.module.css"
import img from "../../assets/News/News211124_1.png"
import img2 from "../../assets/News/News211124_2.png"
import img3 from "../../assets/News/News211124_3.png"
import {AdviceBlock, ShareBlock} from "./News170621";

const News211124 = () => {

    return (
        <div className={s.main}>
            <h1>
                Стартовала регистрация на II Всероссийский правовой диктант для школьников и студентов колледжей #ВПРАВЕ
            </h1>
            <div className={s.date}>
                21 ноября 2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Побьем рекорд вместе! Год назад во Всероссийском правовом диктанте для школьников и студентов
                    колледжей #ВПРАВЕ приняли участие более 500 тысяч человек со всей России. В этом году мы ставим
                    перед собой новую цель — собрать 1 000 000 участников. Приглашаем вас присоединиться к нам и стать
                    частью нашего масштабного проекта.
                    <p></p>
                    Это отличная возможность:
                    <ul>
                        <li>Проверить свои знания в сфере законов России</li>
                        <li>Узнать, как максимально эффективно использовать свои права</li>
                        <li>Научиться защищать свои правовые интересы и способствовать формированию правовой грамотности
                            в стране
                        </li>
                    </ul>

                    Диктант пройдет с 25 ноября по 1 декабря.
                    <p></p>
                    Как принять участие?<br/>
                    Зарегистрироваться на платформе будьвправе.рф<br/>
                    Приглашайте друзей, одноклассников, однокурсников! Давайте вместе повысим уровень правовой
                    грамотности в нашей стране #мывправе<br/>
                    Проект реализуется при поддержке Фонда президентских грантов
                    <p></p>
                    Ссылка на сайт: <a href="https://будьвправе.рф/">https://будьвправе.рф/</a>
                    <p></p>
                </div>
                <div className={s.newsImg}>
                    <img src={img2} alt=""/>
                </div>
                <div className={s.newsImg}>
                    <img src={img3} alt=""/>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News211124
